
import { defineStore } from 'pinia';
import { queryDate } from '~/api/wordcloud';
import { store } from '~/store';
import Lockr from 'lockr'

const globalConfig = defineStore({
  id: 'global-config-store',
  state: (): {
    userinfo: {
      username: string
      getTime: string
    },
    ininRouter: {
      id?: number | string
      name?: string
      url?: string
    }
  } => {
    return {
      userinfo: {
        username: '',
        getTime: '',
      },
      ininRouter: {
        id: 0,
        name: '首页',
        url: '/homepage'
      }
    };
  },
  actions: {
    async getQueryDate() {
      const res = await queryDate({
        page:'wordcloud'
      })
    },
    setLanguage(val: any) {
      const obj = {
        language: val
      }
      Lockr.set('language', obj)
    },
    getLanguage() {
      const lang:any = Lockr.get('language')
      const langArr = ['en_US', 'zh_CN', 'zh_HK', 'ja_JP', 'kr_KR']
      if (lang) {
        const index = langArr.findIndex(item => item == lang.language)
        if (index !== -1) {
          return lang.language
        }
        this.setLanguage('en_US')
        return 'en_US'
      }
      this.setLanguage('en_US')
      return 'en_US'
    },
    setModel(val: any) {
      const obj = {
        model: val
      }
      Lockr.set('model', obj)
    },
    getModel() {
      const model:any = Lockr.get('model')
      const modelArr = ['gpt4', 'gpt4omini', 'gpt4o1', 'gpt4o1-mini', 'deepseek-r1', 'deepseek-v3']
      if (model) {
        const index = modelArr.findIndex(item => item == model.model)
        if (index !== -1) {
          return model.model
        }
        this.setModel('gpt4o1-mini')
        return 'gpt4o1-mini'
      }
      this.setModel('gpt4o1-mini')
      return 'gpt4o1-mini'
    }
  }
});

export default function useGlobalConfig() {
  return globalConfig(store);
}
